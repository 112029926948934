@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

$text-color: #b1bdb4;

$black-primary: #16181b;
$black-secondary: #121417;
$border-color: #4c4c4c;

$breakpoint-medium: 1224px;
$breakpoint-small: 520px;

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

body {
   background: $black-primary;
   background-attachment: fixed;
   color: $text-color;
   font-family: 'Roboto', sans-serif;
   letter-spacing: 0.1px;
}

@mixin line-clamp($number) {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: $number;
   -webkit-box-orient: vertical;
}

::-webkit-scrollbar {
   width: 0px;
}