@import '../../base.scss';

.watchScreen__player {
   height: 60vh;
   background-color: #353946;
   width: 100%;
   margin-bottom: 2rem;
}

@media (max-width: $breakpoint-small) {
   .watchScreen__player {
      height: 35vh;
   }
}