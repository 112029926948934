@import '../../base.scss';

.header {
   display: flex;
   justify-content: space-between;
   align-items: center;

   padding: 1rem 3rem;

   background-color: $black-primary;

   height: 10vh;
   width: 100%;

   position: fixed;
   top: 0%;

   z-index: 999;
   &__menu {
      display: none;
   }
   &__logo {
      width: 30px;
      height: 30px;
      object-fit: contain;
      display: block;
   }

   form {
      flex: 0.6;
      display: flex;
      padding: 0.1rem;
      margin: 0 1rem;
      border-radius: 3px;
      border: 1.2px solid;
      background-color: $black-secondary;

      input {
         width: 100%;
         border: none;
         font-weight: 500;
         background: transparent;
         padding: 0.3rem;
         color: $text-color;

         &:focus {
            outline: none;
         }
      }

      button {
         padding: 0 1.25rem;
         color: $text-color;
         background: transparent;
         border: none;
         &:focus {
            border: none;
         }
      }
   }

   &__icons {
      flex: 0.15;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
         border-radius: 50%;
         width: 40px;
         object-fit: contain;
         margin-left: 5px;
      }
   }
}

@media (max-width: $breakpoint-small) {
   .header {
      padding: 1rem;

      form {
         flex: 1;
      }

      &__menu {
         display: block;
      }
      &__logo {
         display: none;
      }

      &__icons > *:not(img) {
         display: none;
      }
   }
}